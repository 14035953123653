const state = {
    cartItems: []
}
const mutations = {
    UPDATE_CART_ITEMS(state, payload) {
        state.cartItems = payload;
    }
}
const actions = {
    getCartItems({commit}) {
        if (!localStorage.getItem("cart")) {
            localStorage.setItem("cart", JSON.stringify([]));
        }
        const cartItems = JSON.parse(localStorage.getItem("cart"));
        commit('UPDATE_CART_ITEMS', cartItems);
    },
    addCartItem({commit}, item) {
        let cartItem = item.cartItem;
        let quantity = item.quantity;
        if (!localStorage.getItem("cart")) {
            localStorage.setItem("cart", JSON.stringify([]));
        }
        const cartItems = JSON.parse(localStorage.getItem("cart"));
        const index = cartItems.findIndex((storedCartItem) => storedCartItem.id === cartItem.id);
        if (index <= 0) {
            cartItem.quantity = parseInt(quantity);
            cartItems.push(cartItem);
        } else {
            cartItem.quantity = cartItem.quantity + parseInt(quantity);
        }
        commit('UPDATE_CART_ITEMS', cartItems);
        localStorage.setItem("cart", JSON.stringify(cartItems));
    },
    removeCartItem({commit}, cartItem) {
        const cartItems = JSON.parse(localStorage.getItem("cart"));
        const index = cartItems.findIndex((storedCartItem) => storedCartItem.id === cartItem.id);
        cartItems.splice(index, 1);
        commit('UPDATE_CART_ITEMS', cartItems);
        localStorage.setItem("cart", JSON.stringify(cartItems));
    },
    removeAllCartItems({commit}) {
        localStorage.setItem("cart", JSON.stringify([]));
        commit('UPDATE_CART_ITEMS', [])
    },
    increaseCartItem({commit}, cartItem) {
        const cartItems = JSON.parse(localStorage.getItem("cart"));
        const index = cartItems.findIndex((storedCartItem) => storedCartItem.id === cartItem.id);
        if (index >= 0) {
            cartItems[index].quantity += 1;
        }
        commit('UPDATE_CART_ITEMS', cartItems);
        localStorage.setItem("cart", JSON.stringify(cartItems));
    },
    decreaseCartItem({commit}, cartItem) {
        const cartItems = JSON.parse(localStorage.getItem("cart"));
        const index = cartItems.findIndex((storedCartItem) => storedCartItem.id === cartItem.id);
        if (index >= 0) {
            cartItems[index].quantity -= 1;
            if (cartItems[index].quantity === 0) {
                cartItems.splice(index, 1);
            }
            commit('UPDATE_CART_ITEMS', cartItems);
            localStorage.setItem("cart", JSON.stringify(cartItems));
        }
    }
}
const getters = {
    cartItems: state => state.cartItems,
    cartTotalPickUpDiscount: state => {
        const user = JSON.parse(localStorage.getItem('user'));
        let discount = 0
        if(user.DiscountPickupPercent) {
            discount = parseFloat(user.DiscountPickupPercent)
        }
        let total = state.cartItems.reduce((acc, cartItem) => {
            return (cartItem.quantity * cartItem.price) + acc;
        }, 0);
        let discountTotal = total * (1 - (discount / 100))
        return discountTotal.toFixed(2);
    },
    cartTotalDeliveryDiscount: state => {
        const user = JSON.parse(localStorage.getItem('user'));
        let discount = 0
        if(user && user.DiscountDeliveryPercent) {
            discount = user.DiscountDeliveryPercent
        }
        let total = state.cartItems.reduce((acc, cartItem) => {
            return (cartItem.quantity * cartItem.price) + acc;
        }, 0);
        let discountTotal = total * (1 - (discount / 100))
        return discountTotal.toFixed(2);
    },
    cartTotal: state => {
        return state.cartItems.reduce((acc, cartItem) => {
            return (cartItem.quantity * cartItem.price) + acc;
        }, 0).toFixed(2);
    },
    cartDepositTotal: state => {
        return state.cartItems.reduce((acc, cartItem) => {
            let deposit = 0;
            if(cartItem.PackageDeposit && cartItem.SingleDeposit) {
                deposit = cartItem.PackageDeposit;
            } else if(!cartItem.PackageDeposit && cartItem.SingleDeposit) {
                deposit = cartItem.SingleDeposit;
            }

            return parseFloat(deposit) > 0 ? (cartItem.quantity * parseFloat(deposit)) + acc : 0;
        }, 0).toFixed(2);
    },
    cartQuantity: state => {
        return state.cartItems.reduce((acc, cartItem) => {
            return cartItem.quantity + acc;
        }, 0);
    }
}
const cartModule = {
    state,
    mutations,
    actions,
    getters
}
export default cartModule;
