import { createApp } from "vue";
import { createI18n } from 'vue-i18n'
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { FontAwesomeIcon } from './plugins/font-awesome'

const i18n = createI18n({
    locale: 'de',
    'de': {
        style: 'currency',
        currency: 'EUR',
        minimumSignificantDigits: 2,
        maximumSignificantDigits: 2
    }
});

const app = createApp(App)
  .use(router)
  .use(store)
  .use(i18n)
  .component("font-awesome-icon", FontAwesomeIcon);


app.config.globalProperties.$filters = {
    round(value) {
        return (Math.round(value * 100)/100).toFixed(2)
    },
    currency(value) {
        return Number(this.round(value)).toLocaleString('de-DE')
    },
    discountPrice(productItem, user) {
        let price = productItem.PackagePrice || productItem.SinglePrice;
        if(user && user.DiscountPercent) {
            price = price * ((100 - user.DiscountPercent)/100);
            price =  (Math.round(price * 100)/100).toFixed(2);
        }
        return price;
    }
}

app.mount("#app");
