<template>

  <div class="container-fluid">
    <div class="row">
      <div class="col-12 justify-content-end d-flex justify-content-between">
        <div class="form-group">
          <input v-model="search" type="text" class="form-control" placeholder="Suche..." />
        </div>
        <div class="form-group">
          <select v-model="sortOrder" class="form-control" v-if="items.length > 1">
            <option value="0" selected>Sortieren nach:</option>
            <option value="1">Brauerei (A-Z)</option>
            <option value="2">Preis aufsteigend</option>
            <option value="3">Preis absteigend</option>
            <option value="4">IBU aufsteigend</option>
            <option value="5">Alkoholgehalt aufsteigend</option>
            <option value="6">Land (A-Z)</option>
            <option value="7">Sorte (A-Z)</option>
          </select>
        </div>
      </div>
    </div>
  </div>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 col-sm-6 col-lg-4" v-for="productItem in sortedList" :key="productItem.id">
        <ProductListItem :productItem="productItem" :scope="scope"/>
      </div>
    </div>
  </div>
</template>
<script>
import ProductListItem from './ProductListItem'
import {orderBy as _orderBy} from 'lodash';

export default {
  name: "ProductList",
  data() {
    return {search: '', sortOrder: 0}
  },
  components: {
    ProductListItem: ProductListItem
  },
  computed: {
    sortedList() {
      let sortedList = this.items;
      if(this.items.length > 0) {
        let selectedValue = parseInt(this.sortOrder)
        let filteredList = this.items.filter(item => {
          return item.Name.toLowerCase().includes(this.search.toLowerCase())
              || item['BeerStyle'] && item.BeerStyle.toLowerCase().includes(this.search.toLowerCase())
              || item['Brewery'] && item.Brewery.toLowerCase().includes(this.search.toLowerCase())
              || item['CountryOfOrigin'] && item.CountryOfOrigin.toLowerCase().includes(this.search.toLowerCase())
        });

        let sortPriceField = 'customPackagePrice';
        if(this.items.length > 0 && this.items[0].customSinglePrice > 0 && parseInt(this.items[0].customPackagePrice) === 0) {
          sortPriceField = 'customSinglePrice'
        }

        sortedList = filteredList;
        if (selectedValue === 1) {
          sortedList = _orderBy(filteredList, 'Brewery')
        } else if (selectedValue === 2) {
          sortedList = _orderBy(filteredList, function(o) {
            return parseFloat(o[sortPriceField]);
          }, 'asc')
        } else if (selectedValue === 3) {
          sortedList = _orderBy(filteredList,  function(o) {
            return parseFloat(o[sortPriceField]);
          }, 'desc')
        } else if (selectedValue === 4) {
          sortedList = _orderBy(filteredList, 'IBUUnit')
        } else if (selectedValue === 5) {
          sortedList = _orderBy(filteredList, 'AlcoholLevel')
        } else if (selectedValue === 6) {
          sortedList = _orderBy(filteredList, 'CountryOfOrigin')
        } else if (selectedValue === 7) {
          sortedList = _orderBy(filteredList, 'BeerStyle')
        }
      }
      return sortedList;
    },
  },
  methods: {},
  props: ["items", "scope"],
};
</script>

<style>
.container-fluid {
  padding-left: 0;
  padding-right: 0;
}
</style>
