export default function restrictionUrlString() {
    let user = JSON.parse(localStorage.getItem('user'));
    let url = "?Active=true";
    if(user.BlockedArticles.length) {
        user.BlockedArticles.forEach((article) => {
            url += "&" + article.ArticleNumber
        })
    }
    if(user.BlockedProductGroup.length) {
        user.BlockedProductGroup.forEach((productGroup) => {
            url += "&ProductGroupCode_nin=" + productGroup.ProductGroupCode
        })
    }
    return url !== "?" ? encodeURI(url) : ""
}
