<template>
  <div class="container">
    <header class="jumbotron">
      <h3>Welcome</h3>
    </header>
    <div class="container">
      <div class="row">
        <div class="col-4">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title">Bier</h5>
              <router-link to="/beers" class="btn btn-primary">
                zu den Produkten
              </router-link>
            </div>
          </div>
        </div>
        <div class="col-4">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title">Alkoholfreie Getränke</h5>
              <router-link to="/afgs" class="btn btn-primary">
                zu den Produkten
              </router-link>
            </div>
          </div>
        </div>
        <div class="col-4">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title">Fassbiere</h5>
              <router-link to="/draftbeers" class="btn btn-primary">
                zu den Produkten
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
