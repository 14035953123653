<template>
  <div class="card" v-if="currentUser.ShippingAddress">
    <div class="card-body">
      <h3>Lieferadresse</h3>
      <p>{{ currentUser.ShippingAddress.FirstName }} {{ currentUser.ShippingAddress.LastName }}</p>
      <p>{{ currentUser.ShippingAddress.StreetNumber }}<br>
        {{ currentUser.ShippingAddress.ZIP }} {{ currentUser.ShippingAddress.City }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'shippingAddress',
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    }
  },
  mounted() {
    if (!this.currentUser) {
      this.$router.push('/login');
    }
  }
};
</script>
