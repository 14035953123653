<template>
<div class="box">
  <div class="cart-item__details">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <button class="btn btn-danger float-right" v-if="editable" @click="removeCartItem(cartItem)"><font-awesome-icon icon="trash" /></button>
          <p class="h4">{{cartItem.Name}}</p>
        </div>
      </div>
      <div class="row no-gutters">
        <div class="col-3"><img class="float-left mr-2" width="150" v-if="cartItem.SingleImage && cartItem.SingleImage.formats && cartItem.SingleImage.formats.thumbnail" v-bind:src="cartItem.SingleImage.formats.thumbnail.url" v-bind:alt="cartItem.Name"></div>
        <div class="col-2">Menge: {{cartItem.quantity}}<br v-if="cartItem.VesselType">{{cartItem.VesselType}}<br v-if="cartItem.PackageType">{{cartItem.PackageType}}</div>
        <div class="col-4">Einzelpreis: {{singlePrice}} EUR<br><span>Pfand / VPE: <span v-if="cartItem.PackageDeposit && cartItem.SingleDeposit">{{cartItem.PackageDeposit}}</span><span v-if="!cartItem.PackageDeposit && cartItem.SingleDeposit">{{cartItem.SingleDeposit}}</span> EUR</span></div>
        <div class="col-3">Summe: {{ $filters.round(cartItem.quantity * singlePrice) }} EUR<br>
          <span v-if="cartItem.PackageDeposit && cartItem.SingleDeposit">{{$filters.round(cartItem.quantity * cartItem.PackageDeposit)}} EUR</span>
          <span v-if="!cartItem.PackageDeposit && cartItem.SingleDeposit">{{$filters.round(cartItem.quantity * cartItem.SingleDeposit)}} EUR</span>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <p class="text-right">
            <button class="btn btn-primary d-inline-block" v-if="editable" @click="decreaseCartItem(cartItem)"><font-awesome-icon icon="minus-square" /></button>
            <span class="d-inline-block text-center  mr-4 ml-4">{{cartItem.quantity}}</span>
            <button class="btn btn-primary d-inline-block" v-if="editable" @click="increaseCartItem(cartItem)"><font-awesome-icon icon="plus-square" /></button>
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
</template>
<script>
import {mapActions} from 'vuex';
export default {
  name: 'CartListItem',
  props: ['cartItem', 'editable'],
  methods: {
    ...mapActions([
      'addCartItem',
      'removeCartItem',
      'increaseCartItem',
      'decreaseCartItem',
    ])
  },
  computed: {
    singlePrice() {
      if(!this.cartItem.customPackagePrice) {
        return this.cartItem.customSinglePrice
      }
      return this.cartItem.customPackagePrice
    },
    price(qty) {
      return (Math.round(this.singlePrice * qty * 100)/100).toFixed(2);

    }
  }
}
</script>
