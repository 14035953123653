<template>
  <div class="card mb-3">
    <img class="card-img-top" v-if="productItem.SingleImage && productItem.SingleImage.formats && productItem.SingleImage.formats.small"
         v-bind:src=" productItem.SingleImage.formats.small.url" v-bind:alt="productItem.Name">
    <div class="card-body">
      <h5 class="card-title">{{ productItem.Name }} <span v-if="productItem.Brewery"> - {{ productItem.Brewery }}</span></h5>
      <div class="card-text" v-html="productItem.ShortDescription"></div>
      <table class="table">
        <tr v-if="productItem.BeerStyle">
          <td>Sorte:</td>
          <td>{{ productItem.BeerStyle }}</td>
        </tr>
        <tr v-if="productItem.Brewery">
          <td>Brauerei:</td>
          <td>{{ productItem.Brewery }}</td>
        </tr>
        <tr v-if="productItem.AlcoholLevel">
          <td>Alkoholgehalt:</td>
          <td>{{ productItem.AlcoholLevel }}</td>
        </tr>
        <tr v-if="productItem.IBUUnit">
          <td>IBU:</td>
          <td>{{ productItem.IBUUnit }}</td>
        </tr>
        <tr v-if="productItem.CountryOfOrigin">
          <td>Ursprung:</td>
          <td>{{ productItem.CountryOfOrigin }}</td>
        </tr>
      </table>
      <div class="jumbotron">
        <div class="row">
          <div class="col-12">Listenpreis: {{ productItem.PackagePrice || productItem.SinglePrice }} EUR*</div>
          <div class="col-12 h6">Mit Rabattierung: <span v-if="productItem.customPackagePrice">{{ productItem.customPackagePrice }}</span><span v-if="productItem.customSinglePrice && !productItem.customPackagePrice">{{  productItem.customSinglePrice }}</span> EUR*</div>
          <div class="col"><input class="form-control" type="number" min="1" max="999" name="quantity"
                                  v-model="quantity"/></div>
          <div class="col"><a href="#" class="btn btn-primary" @click="addProductToCart(productItem)">
            <font-awesome-icon icon="cart-plus"/>
          </a></div>
        </div>
        <p><small>zzgl. Pfand und {{ productItem.TaxPercent }}% Ust.</small></p>
        <p><small class="d-block">*pro VPE (<span v-if="productItem.PackageType">{{ productItem.PackageType}}</span><span v-if="!productItem.PackageType">Fass</span>)</small></p>
      </div>
    </div>
  </div>
</template>
<script>
import {mapActions} from 'vuex'
import {createToast} from 'mosha-vue-toastify';
import 'mosha-vue-toastify/dist/style.css'

export default {
  name: "ProductListItem",
  props: ["productItem", "scope"],
  data() {
    return {
      quantity: 1
    }
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    price() {
      return this.$filters.discountPrice(this.productItem, this.$store.state.auth.user);
    }
  },
  methods: {
    ...mapActions(["addCartItem"]),
    addProductToCart(productItem) {
      productItem['price'] = typeof this.productItem.customPackagePrice !== 'undefined' && this.productItem.customPackagePrice > 0 ? this.productItem.customPackagePrice :  this.productItem.customSinglePrice
      productItem['scope'] = this.scope
      this.addCartItem({cartItem: productItem, quantity: this.quantity});
      createToast({
            title: 'Artikel hinzugefügt',
            description: `${productItem.Name} wurde zum Warenkorb hinzugefügt`
          },
          {
            type: 'success',
            hideProgressBar: true,
            position: 'bottom-right'
          })
    }
  },
};
</script>

<style>
.jumbotron {
  padding: 2rem 1rem 1rem;
}
.card-title {
  height: 50px;
  text-overflow: ellipsis;
}
.card-text {
  display: -webkit-box;
  min-height: 140px;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 2rem;
}
</style>
