import axios from 'axios';
import authHeader from './auth-header';

const API_URL = 'https://bier-events-profimarkt-prod.herokuapp.com/';

class OrderService {
    constructor() {
        this.pickUp = false;
        this.delivery = false;
        this.message = "";
    }

    setMessage(msg) {
        this.message = msg
    }

    getMessage() {
        return this.message;
    }

    isPickUp() {
        return this.pickUp
    }

    setPickUp(pickUp) {
        this.pickUp = pickUp
    }

    isDelivery() {
        return this.delivery
    }

    setDelivery(delivery) {
        this.delivery = delivery
    }

    sendOrder(json) {
        this.delivery = false;
        this.pickUp = false;
        this.message = "";

        let headers = authHeader();
        headers['Content-Type'] = "application/json"

        return axios.post(API_URL + 'orders', json, {
            headers: headers
        });
    }
}

export default new OrderService();
