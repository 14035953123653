<template>
  <div class="container">
    <header class="jumbotron">
      <h3>Biere</h3>
    </header>
    <div class="container">
      <div class="row">
        <ProductList :items="beerItems" :scope="'beer'" />
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import ProductList from "../components/product/ProductList";

export default {
  name: "Beers",
  components: {ProductList},
  computed: {
    ...mapGetters([
      'beerItems'
    ])
  },
  created() {
    this.$store.dispatch('getBeerItems');
  },
};
</script>
