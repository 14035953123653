<template>
  <p v-if="currentUser && currentUser.ShippingAddress">
    <span v-if="currentUser.ShippingAddress.CompanyName">{{ currentUser.ShippingAddress.CompanyName }}, </span>
    <span v-if="currentUser.ShippingAddress.FirstName && currentUser.ShippingAddress.LastName">{{ currentUser.ShippingAddress.FirstName }} {{ currentUser.ShippingAddress.LastName }},</span> {{ currentUser.ShippingAddress.StreetNumber }}, {{ currentUser.ShippingAddress.ZIP }} {{ currentUser.ShippingAddress.City }}
  </p>
</template>

<script>
export default {
  name: 'inlineShippingAddress',
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    }
  },
  mounted() {
    if (!this.currentUser) {
      this.$router.push('/login');
    }
  }
};
</script>
