<template>
  <div class="card" v-if="currentUser.BillingAddress">
    <div class="card-body">
      <h3>Rechnungsadresse</h3>
      <p>{{ currentUser.BillingAddress.FirstName }} {{ currentUser.BillingAddress.LastName }}</p>
      <p>{{ currentUser.BillingAddress.StreetNumber }}<br>
        {{ currentUser.BillingAddress.ZIP }} {{ currentUser.BillingAddress.City }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'billingAddress',
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    }
  },
  mounted() {
    if (!this.currentUser) {
      this.$router.push('/login');
    }
  }
};
</script>
