<template>
  <ul class="list-group">
    <li class="cart-item list-group-item" v-for="cartItem in cartItems" :key="cartItem.id">
      <CartListItem :cartItem="cartItem" :editable="editable" />
    </li>
  </ul>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import CartListItem from "./CartListItem";

export default {
  name: "CartList",
  properties: {
    props: ['editable'],
  },
  components: {
    CartListItem
  },
  computed: {
    ...mapGetters(["cartItems", "cartTotal", "cartQuantity"]),
  },
  created() {
    this.$store.dispatch("getCartItems");
  },
  methods: {
    ...mapActions(["removeAllCartItems"]),
    checkout() {
      this.$router.push("/checkout");
    }
  }
};
</script>
