<template>
  <div id="checkout">
    <div class="jumbotron">
      <h1>Bestellabschluss</h1>
    </div>
    <div class="row">
      <div class="col-6">
        <billing-address />
      </div>
      <div class="col-6">
        <shipping-address />
      </div>
      <div class="col-12 mt-4 mb-4">
        <h2>{{cartQuantity}} Artikel</h2>
        <CartList :editable="false" />
      </div>
    </div>
    <div class="card mb-4" v-if="delivery">
      <div class="card-body">
        <h4>Versandart: Lieferung</h4>
        <p>Lieferung an folgende Adresse:</p>
        <div><InlineShippingAddress /></div>
      </div>
    </div>
    <div class="card mb-4" v-if="pickUp">
      <div class="card-body">
        <h4>Versandart: Abholung</h4>
        <p>Abholung bei uns in der Euler Str. 21.</p>
        <p class="font-italic mt-4">Unsere Lageröffnungzeiten:<br>Mo. - Fr. 10-16 Uhr<br>(Invididuelle Abholung nach Absprache)</p>
      </div>
    </div>
    <div class="card mb-4" v-if="message">
      <div class="card-body">
        <p class="font-weight-bold">Kommentar / Nachricht zu deiner Bestellung:</p>
        <div>{{ message }}</div>
      </div>
    </div>

  <table class="table mt-4 text-right">
    <tr class="h6">
      <td>Zwischensumme</td>
      <td>{{ subtotal }} EUR</td>
    </tr>
    <tr>
      <td>MwSt.</td>
      <td>{{ tax }} EUR</td>
    </tr>
    <tr class="h4">
      <td>Gesamtsumme</td>
      <td>{{ cartTotal }} EUR</td>
    </tr>
  </table>
    <div class="buttons mt-4 mb-5 d-flex justify-content-end">
      <button class="btn btn-success" @click="sendOrder">
        <span>Bestellung kostenpflichtig absenden</span>
      </button>
    </div>
  </div>

</template>

<script>
import { mapGetters, mapActions } from "vuex";
import BillingAddress from "../user/billingAddress";
import ShippingAddress from "../user/shippingAddress";
import InlineShippingAddress from "../user/inlineShippingAddress";
import CartList from "../cart/CartList";
import OrderService from "../../services/order.service";
import {createToast} from 'mosha-vue-toastify';

export default {
  name: "Checkout",
  components: {CartList, ShippingAddress, BillingAddress, InlineShippingAddress},
  data() {
    return {
      delivery: OrderService.isDelivery(),
      pickUp: OrderService.isPickUp(),
      message: OrderService.getMessage(),
    }
  },
  created() {
    this.$store.dispatch("getCartItems");
  },
  computed: {
    ...mapGetters(["cartItems", "cartTotal", "cartQuantity", "draftBeerItemById", "beerItemById", "afgsItemById"]),
    currentUser() {
      return this.$store.state.auth.user;
    },
    subtotal() {
      let subtotal = this.cartTotal / 119 * 100;
      return (Math.round(subtotal * 100)/100).toFixed(2);
    },
    tax() {
      let tax = this.cartTotal / 119 * 19;
      return (Math.round(tax * 100)/100).toFixed(2);
    },
  },
  methods: {
    ...mapActions(["removeAllCartItems"]),
    sendOrder() {
      if(!this.currentUser || !this.currentUser.id) {
        alert("Fehlerhafter Login. Bitte ausloggen und wieder einloggen.")
        return;
      }
      let orderJson = {};
      orderJson['OrderedBeverage'] = [];
      orderJson['Comment'] = OrderService.getMessage();
      orderJson['IsPickup'] = OrderService.isPickUp();
      orderJson['IsDelivery'] = OrderService.isDelivery();
      orderJson['users_permissions_user'] = this.currentUser.id
      if(this.$store.getters['cartQuantity'] > 0) {
        this.$store.getters['cartItems'].forEach((productItem) => {
          let item = {};
          item["UnitType"] = "package";
          let component = "order.ordered-beer";
          let identifier = "bier";
          if(productItem.scope === "afgs") {
            component = "order.ordered-afg";
            item["UnitType"] = "package";
            identifier = "afg";
          }
          if(productItem.scope === "draftBeer") {
            component = "order.ordered-beerkeg";
            identifier = "bierfass";
            delete item["UnitType"];
          }
          item['__component'] = component;
          item["Quantity"] = productItem.quantity;
          item[identifier] = productItem.id;

          orderJson['OrderedBeverage'].push(item)
          });
        }
      // send order
      OrderService.sendOrder(orderJson);
      createToast({
            title: 'Bestellung erfolgreich',
            description: `Die Bestellung wurde erfolgreich übermittelt`
          },
          {
            type: 'success',
            hideProgressBar: true
          })
      this.$store.dispatch("removeAllCartItems")
      this.$router.push("/");
    }
  },
  mounted() {
    if (!this.currentUser) {
      this.$router.push('/login');
    }
  }
};
</script>
