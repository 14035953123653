<template>
  <nav class="navbar navbar-expand navbar-dark bg-dark">
    <a href="/" class="navbar-brand">Profishop</a>
    <div class="navbar-nav mr-auto">
      <li class="nav-item" v-if="currentUser">
        <router-link to="/beers" class="nav-link">
          Biere
        </router-link>
      </li>
      <li class="nav-item" v-if="currentUser">
        <router-link to="/afgs" class="nav-link">
          Alkoholfreie Getränke
        </router-link>
      </li>
      <li class="nav-item" v-if="currentUser">
        <router-link to="/draftbeers" class="nav-link">
          Fassbiere
        </router-link>
      </li>
    </div>

    <div v-if="!currentUser" class="navbar-nav ml-auto">
      <li class="nav-item">
        <router-link to="/register" class="nav-link">
          <font-awesome-icon icon="user-plus" /> Sign Up
        </router-link>
      </li>
      <li class="nav-item">
        <router-link to="/login" class="nav-link">
          <font-awesome-icon icon="sign-in-alt" /> Login
        </router-link>
      </li>
    </div>

    <div v-if="currentUser" class="navbar-nav ml-auto">
      <li class="nav-item">
        <router-link to="/cart" class="nav-link">
          <font-awesome-icon icon="shopping-cart" />
        <span>&nbsp; {{cartQuantity}}</span>
        </router-link>
      </li>
      <li class="nav-item">
        <router-link to="/profile" class="nav-link">
          <font-awesome-icon icon="user" />&nbsp;
          {{ currentUser.username }}
        </router-link>
      </li>
      <li class="nav-item">
        <a class="nav-link" @click.prevent="logOut">
          <font-awesome-icon icon="sign-out-alt" /> LogOut
        </a>
      </li>
    </div>
  </nav>
</template>

<script>
import {mapGetters} from "vuex"
export default {
  computed: {
    ...mapGetters([
      'cartQuantity'
    ]),
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
  created() {
    this.$store.dispatch("getCartItems");
  },
  methods: {
    logOut() {
      this.$store.dispatch('auth/logout');
      this.$router.push('/login');
    }
  }
};
</script>

<style>
.router-link-active {font-weight: bold}
</style>
