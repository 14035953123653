import axios from "axios";
import authHeader from "../../../services/auth-header";
import restrictionUrlString from "../../../helper/restrictionUrl";

const API_URL = 'https://bier-events-profimarkt-prod.herokuapp.com';

const state = {
    draftBeerItems: [],
    beerItems: [],
    afgsItems: []
}

const mutations = {
    UPDATE_BEER_ITEMS (state, payload) {
        state.beerItems = payload;
    },
    UPDATE_AFGS_ITEMS (state, payload) {
        state.afgsItems = payload;
    },
    UPDATE_DRAFTBEER_ITEMS (state, payload) {
        state.draftBeerItems = payload;
    }
}
const actions = {
    getBeerItems ({ commit }) {
        axios.get(API_URL + `/biers` + restrictionUrlString(),  { headers: authHeader()}).then((response) => {
            let data = [];
            const user = JSON.parse(localStorage.getItem('user'));
            response.data.map(function (productItem) {
                let discount = 0
                user.SpecialDiscount.forEach((specialDiscountItem) => {
                    if(typeof specialDiscountItem.biers !== 'undefined' && specialDiscountItem['biers'].length > 0) {
                        specialDiscountItem['biers'].forEach((discountItem) => {
                            if(discountItem.id === productItem.id) {
                                // item based discount
                                discount = specialDiscountItem.DiscountPercent;
                            }
                        });
                    }
                });
                if(parseFloat(user.DiscountPercent) > 0) {
                    // generic user discount
                    discount = discount + user.DiscountPercent
                }
                if(discount > 0) {
                    productItem['customPackagePrice'] = (productItem.PackagePrice * (1 - (discount / 100))).toFixed(2)
                    productItem['customSinglePrice'] = (productItem.SinglePrice * (1 - (discount / 100))).toFixed(2)
                } else {
                    productItem['customPackagePrice'] = productItem.PackagePrice
                    productItem['customSinglePrice'] = productItem.SinglePrice
                }
                data.push(productItem);
            });

            commit('UPDATE_BEER_ITEMS', data)
        });
    },
    getDraftBeerItems ({ commit }) {
        const user = JSON.parse(localStorage.getItem('user'));
        axios.get(API_URL + `/bierfasses` + restrictionUrlString(),  { headers: authHeader()}).then((response) => {
            let data = [];

            response.data.map(function (productItem) {
                let discount = 0
                user.SpecialDiscount.forEach((specialDiscountItem) => {
                    if(typeof specialDiscountItem.bierfasses !== 'undefined' && specialDiscountItem['bierfasses'].length > 0) {
                        specialDiscountItem['bierfasses'].forEach((discountItem) => {
                            if(discountItem.id === productItem.id) {
                                // item based discount
                                discount = specialDiscountItem.DiscountPercent;
                            }
                        });
                    }
                });
                if(parseFloat(user.DiscountPercent) > 0) {
                    // generic user discount
                    discount = discount + user.DiscountPercent
                }

                if(discount > 0) {
                    productItem['customPackagePrice'] = typeof productItem.PackagePrice !== 'undefined' ? (productItem.PackagePrice * (1 - (discount / 100))).toFixed(2) : 0
                    productItem['customSinglePrice'] = (productItem.SinglePrice * (1 - (discount / 100))).toFixed(2)
                } else {
                    productItem['customPackagePrice'] = typeof productItem.PackagePrice !== 'undefined' ? productItem.PackagePrice : 0
                    productItem['customSinglePrice'] = productItem.SinglePrice
                }
                data.push(productItem);
            });

            commit('UPDATE_DRAFTBEER_ITEMS', response.data)
        });
    },
    getAfgsItems ({ commit }) {
        axios.get(API_URL + `/afgs` + restrictionUrlString(),  { headers: authHeader()}).then((response) => {
            let data = [];
            const user = JSON.parse(localStorage.getItem('user'));
            response.data.map(function (productItem) {
                let discount = 0
                user.SpecialDiscount.forEach((specialDiscountItem) => {
                    if(typeof specialDiscountItem.afgs !== 'undefined' && specialDiscountItem['afgs'].length > 0) {
                        specialDiscountItem['afgs'].forEach((discountItem) => {
                            if(discountItem.id === productItem.id) {
                                // item based discount
                                discount = specialDiscountItem.DiscountPercent;
                            }
                        });
                    }
                });
                if(parseFloat(user.DiscountPercent) > 0) {
                    // generic user discount
                    discount = discount + user.DiscountPercent
                }

                if(discount > 0) {
                    productItem['customPackagePrice'] = (productItem.PackagePrice * (1 - (discount / 100))).toFixed(2)
                    productItem['customSinglePrice'] = (productItem.SinglePrice * (1 - (discount / 100))).toFixed(2)
                } else {
                    productItem['customPackagePrice'] = productItem.PackagePrice
                    productItem['customSinglePrice'] = productItem.SinglePrice
                }
                data.push(productItem);
            });
            commit('UPDATE_AFGS_ITEMS', response.data)
        });
    }
}

const getters = {
    beerItems: state => state.beerItems,
    afgsItems: state => state.afgsItems,
    draftBeerItems: state => state.draftBeerItems,
    draftBeerItemById: (state) => (id) => {
        return state.draftBeerItems.find(productItem => productItem.id === id)
    },
    beerItemById: (state) => (id) => {
        return state.beerItems.find(productItem => productItem.id === id)
    },
    afgsItemById: (state) => (id) => {
        return state.afgsItems.find(productItem => productItem.id === id)
    }
}

const productModule = {
    state,
    mutations,
    actions,
    getters
}

export default productModule;
