<template>
  <div class="container">
    <header class="jumbotron">
      <h3>Alkoholfreie Getränke</h3>
    </header>
    <div class="container">
    <div class="row">
      <ProductList :items="afgsItems" :scope="'afgs'" />
    </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import ProductList from "./product/ProductList";

export default {
  name: "Afgs",
  components: {ProductList},
  computed: {
    ...mapGetters([
      'afgsItems'
    ])
  },
  created() {
    this.$store.dispatch('getAfgsItems');
  },
};
</script>
