import axios from 'axios';

const API_URL = 'https://bier-events-profimarkt-prod.herokuapp.com/';

class AuthService {
  login(user) {
    return axios
      .post(API_URL + 'auth/local', {
        identifier: user.username,
        password: user.password
      })
      .then(response => {
        if (response.data.jwt) {
          localStorage.setItem('token', JSON.stringify(response.data.jwt));
          localStorage.setItem('user', JSON.stringify(response.data.user));
        }

        return response.data;
      });
  }

  logout() {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
  }

  register(user) {
    return axios.post(API_URL + 'signup', {
      username: user.username,
      email: user.email,
      password: user.password
    });
  }
}

export default new AuthService();
