<template>
  <div id="cart">
    <div class="jumbotron">
      <h1>Warenkorb</h1>
    </div>
    <p v-if="!cartItems.length" class="cart-empty-text has-text-centered">
      Der Warenkorb ist leer.
    </p>
    <ul class="list-group">
      <li class="cart-item list-group-item" v-for="cartItem in cartItems" :key="cartItem.id">
        <CartListItem :cartItem="cartItem" :editable="true" />
      </li>
    </ul>
    <div class="buttons d-flex justify-content-end mt-3">
      <button class="btn btn-danger" @click="removeAllCartItems">
        <span>Warenkorb leeren</span>
      </button>
    </div>
    <table class="table mt-4">
      <tr>
        <td>Gesamtmenge (VPE)</td>
        <td>{{ cartQuantity }}</td>
      </tr>
      <tr class="h4">
        <td>Gesamtsumme (Ware netto)</td>
        <td>{{ cartTotal }} EUR</td>
      </tr>
      <tr class="h5">
        <td>Gesamtsumme (Pfand netto)</td>
        <td>{{ cartDepositTotal }} EUR</td>
      </tr>
    </table>
    <div class="card mb-3 mt-4">
      <div class="card-body d-flex flex-column">
        <label>Kommentar / Nachricht zu deiner Bestellung:</label>
        <textarea v-model="message" />
      </div>
    </div>
    <div class="card mb-3">
      <div class="card-body">
      <div class="row">
        <div class="col-8">
          <label class="">
            <input type="checkbox" v-model="delivery" /><span class="font-weight-bold"> Ich möchte die Ware liefern lassen:</span><br>
            (Mindestbestellmenge 10 VPE)
          </label>
          <div class="font-italic mt-4">Deine hinterlegte Lieferadresse:<br>
          <InlineShippingAddress />
          </div>
        </div>
        <div class="col-4 d-flex flex-column">
          <strong>Dein Lieferpreis:</strong>
          <p class="alert alert-warning p-3" v-if="cartQuantity < 10">Die Mindestbestellmenge (10 VPE) wurde noch nicht erreicht.</p>
          <button :disabled="!cartItems.length || !delivery || cartQuantity < 10" class="btn btn-primary" @click="checkout(true, false)">
            Bestellung abschließen (<span class="has-text-weight-bold">{{ cartTotalDeliveryDiscount }}</span> EUR)
          </button>
          <small>Inkl. Pfand und zzgl. 19% MwSt.</small>
        </div>
      </div>
      </div>
    </div>
    <div class="card mb-3">
      <div class="card-body">
      <div class="row">
        <div class="col-8">
          <label class="font-weight-bold">
            <input type="checkbox" v-model="pickUp" /> Ich möchte die Ware selber in der Euler Str. 21 abholen:<br>
          </label>
          <p class="font-italic mt-4">Unsere Lageröffnungzeiten:<br>Mo. - Fr. 10-16 Uhr<br>(Invididuelle Abholung nach Absprache)</p>
        </div>
        <div class="col-4 d-flex flex-column">
          <strong>Dein Abholpreis:</strong>
          <button :disabled="!cartItems.length || !pickUp" class="btn btn-primary" @click="checkout(false, true)">
            Bestellung abschließen (<span class="has-text-weight-bold">{{ cartTotalPickUpDiscount }}</span> EUR)
          </button>
          <small>Inkl. Pfand und zzgl. 19% MwSt.</small>
        </div>
      </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import CartListItem from "../components/cart/CartListItem";
import InlineShippingAddress from "../components/user/inlineShippingAddress";
import OrderService from "../services/order.service";

export default {
  name: "CartList",
  components: {
    InlineShippingAddress,
    CartListItem
  },
  computed: {
    ...mapGetters(["cartItems", "cartTotal", "cartQuantity","cartDepositTotal","cartTotalPickUpDiscount", "cartTotalDeliveryDiscount"]),
  },
  data() {
    return {
      delivery: OrderService.isDelivery(),
      pickUp: OrderService.isPickUp(),
      message: OrderService.getMessage(),
    }
  },
  created() {
    this.$store.dispatch("getCartItems");
  },
  methods: {
    ...mapActions(["removeAllCartItems"]),
    checkout(delivery, pickup) {
      OrderService.setDelivery(delivery);
      OrderService.setPickUp(pickup);
      OrderService.setMessage(this.message);

      this.$router.push("/checkout");
    }
  }
};
</script>

<style>
textarea {
  height: 200px;
}

label:hover {
  cursor: pointer;
}
</style>
