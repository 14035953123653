import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
  faHome,
  faUser,
  faUserPlus,
  faShoppingCart,
  faCartPlus,
  faCartArrowDown,
  faSignInAlt,
  faSignOutAlt,
  faTrash,
  faPlusSquare,
  faMinusSquare,
} from "@fortawesome/free-solid-svg-icons";

library.add(faHome, faUser, faUserPlus, faSignInAlt, faSignOutAlt, faShoppingCart, faCartArrowDown, faCartPlus, faTrash, faPlusSquare, faMinusSquare);

export { FontAwesomeIcon };
