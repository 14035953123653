import { createWebHistory, createRouter } from "vue-router";
import Home from "./views/Home.vue";
import Afgs from "./components/Afgs.vue";
import Login from "./components/Login.vue";
import Register from "./components/Register.vue";
import Cart from "./views/Cart.vue";
import Checkout from "./components/checkout/Checkout.vue";
import Beer from "./views/Beer.vue";
import DraftBeer from "./views/DraftBeer.vue";

// lazy-loaded
const Profile = () => import("./components/Profile.vue")
const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
  },
  {
    path: "/beers",
    name: "beers",
    component: Beer,
  },
  {
    path: "/draftbeers",
    name: "drfatbeers",
    component: DraftBeer,
  },
  {
    path: "/afgs",
    component: Afgs,
  },
  {
    path: "/login",
    component: Login,
  },
  {
    path: "/register",
    component: Register,
  },
  {
    path: '/cart',
    component: Cart
  },
  {
    path: '/checkout',
    component: Checkout
  },
  {
    path: "/profile",
    name: "profile",
    // lazy-loaded
    component: Profile,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
   const publicPages = ['/login', '/register', '/home'];
   const authRequired = !publicPages.includes(to.path);
   const loggedIn = localStorage.getItem('user');

   // trying to access a restricted page + not logged in
   // redirect to login page
   if (authRequired && !loggedIn) {
     next('/login');
   } else {
     next();
   }
});

export default router;
